import React from 'react';
import { View, Text } from 'react-native';

const CompanyProfileScreen = () => {
    return (
        <View>
            <Text>Company Profile Screen</Text>
            {/* Company Profile ekranına ait diğer bileşenler */}
        </View>
    );
};

export default CompanyProfileScreen;
