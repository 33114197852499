import React, { useState, useContext, useEffect } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import BabyBlueInput from '../../components/BabyBlueInput';
import CustomButton from '../../components/CustomButton';
import PhotoPicker from '../../components/PhotoPicker';
import UserContext from '../../contexts/UserContext';


const CompanySettingsScreen = () => {
    const { user, api } = useContext(UserContext)
    const [companyName, setCompanyName] = useState('');
    const [city, setCity] = useState('');
    const [district, setDistrict] = useState('');
    const [aboutUs, setAboutUs] = useState('');
    const [phone, setPhone] = useState('');
    const [photo, setPhoto] = useState({
        base64: "",
        url: ""
    })

    const handleSaveSettings = () => {
        const url = api.url + "update-user-profile"; // Replace with the actual endpoint URL
        const requestBody = {
            token: user.token, // Replace with the actual JWT token            
            phoneNumber: phone, // Update with the appropriate phone number
            city: city, // Update with the appropriate city
            district: district, // Update with the appropriate district
            about: aboutUs,
            fullname: companyName

        };

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
            redirect: 'follow',
        };

        fetch(url, requestOptions)
            .then(response => response.json())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
    };


    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                // Fetch user details when the page is updated        
                const response = await user.getProfile(user.token, user.id);
                console.table(response);
                const userData = response;
                // Update your component's state with the fetched user data
                setCompanyName(userData.fullname);
                setCity(userData.city);
                setDistrict(userData.district);
                setAboutUs(userData.about);
                setPhone(userData.phoneNumber);
            } catch (error) {
                console.log('Error fetching user details:', error);
            }
        };

        fetchUserDetails();
    }, []);
    return (
        <View style={styles.container}>
            <View style={styles.formContainer}>
                <Text style={styles.title}>Company Settings Screen</Text>
                <PhotoPicker setImage={setPhoto}></PhotoPicker>
                <BabyBlueInput
                    label="Firma Adı"
                    placeholder="Firma Adı"
                    value={companyName}
                    onChangeText={setCompanyName}
                />

                <BabyBlueInput
                    label="Telefon Numarası"
                    placeholder=""
                    value={phone}
                    onChangeText={setPhone}
                />
                <BabyBlueInput
                    label="Şehir"
                    placeholder="Şehir"
                    value={city}
                    onChangeText={setCity}
                />

                <BabyBlueInput
                    label="İlçe"
                    placeholder="İlçe"
                    value={district}
                    onChangeText={setDistrict}
                />

                <BabyBlueInput
                    label="Hakkımızda"
                    placeholder="Hakkımızda"
                    value={aboutUs}
                    onChangeText={setAboutUs}
                    numberOfLines={8}
                />

                <View style={styles.buttonContainer}>
                    <CustomButton title="Save Settings" onPress={handleSaveSettings} />
                </View>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    formContainer: {
        width: '50%',
        padding: 20,
        borderRadius: 10,
        backgroundColor: '#fff',
        elevation: 3,
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 20,
    },
    buttonContainer: {
        marginTop: 10,
    },
});

export default CompanySettingsScreen;
