import React from 'react';
import { View, Text, Dimensions } from 'react-native';

const { width } = Dimensions.get('window');

const VehicleItem = ({ item }) => {
    const isSmallScreen = width < 600; // Adjust the breakpoint as needed

    return (
        <View style={{
            flexDirection: isSmallScreen ? 'column' : 'row',
            justifyContent: isSmallScreen ? "flex-start" : "center",
            marginTop: 25,
            flexWrap: 'wrap',
            backgroundColor: 'white',
            borderRadius: 8,
            shadowColor: '#000',
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.25,
            shadowRadius: 3.84,
            elevation: 5
        }}>
            <View style={{ width: isSmallScreen ? '100%' : '30%', padding: 16, justifyContent: isSmallScreen ? "center" : "flex-end", }}>
                <Text style={{ fontSize: 18, fontWeight: 'bold', marginBottom: 8 }}>Firma: {item.owner}</Text>
                <Text style={{ fontSize: 16, marginBottom: 8 }}>Araç Markası: {item.brand}</Text>
                <Text style={{ fontSize: 16, marginBottom: 8 }}>Araç Modeli: {item.model}</Text>
                <Text style={{ fontSize: 16, marginBottom: 8 }}>Yılı: {item.year}</Text>
            </View>

            <View style={{ width: isSmallScreen ? '100%' : '30%', padding: 16, justifyContent: isSmallScreen ? "center" : "flex-end", }}>
                <Text style={{ fontSize: 16, marginBottom: 8 }}>Araç Tipi: {item.vehicleType}</Text>
                <Text style={{ fontSize: 16, marginBottom: 8 }}>Vites Tipi: {item.transmissionType}</Text>
                <Text style={{ fontSize: 16, marginBottom: 8 }}>Araç Lokasyonu: {item.location}</Text>
            </View>

            <View style={{ width: isSmallScreen ? '100%' : '30%', padding: 16, justifyContent: isSmallScreen ? "center" : "flex-end", }}>
                <Text style={{ fontSize: 16, marginBottom: 8 }}>İletişim Telefon: {item.contact_phone}</Text>
                <Text style={{ fontSize: 16, marginBottom: 8 }}>İlietişim Eposta: {item.contact_email}</Text>
                <Text style={{ fontSize: 16, marginBottom: 8 }}>Ücret Teklifi: {item.salaryOffer}</Text>
            </View>
        </View>
    );
};

export default VehicleItem;
