import { Alert, Platform } from 'react-native';

const alertPolyfill = (title, description, options) => {
    const message = [title, description].filter(Boolean).join('\n');
    const result = window.confirm(message);

    options.forEach(option => {
        if (option.style === 'cancel' && !result && option.onPress) {
            option.onPress();
        } else if (option.style !== 'cancel' && result && option.onPress) {
            option.onPress();
        }
    });
};

const alert = Platform.OS === 'web' ? alertPolyfill : Alert.alert;

export default function customAlert(title, description, options) {
    const formattedOptions = (options || [])
        .filter(option => option && typeof option === 'object')
        .map(option => {
            const { title, onPress, style } = option;
            return {
                text: title || '',
                onPress: onPress || (() => { }),
                style: style || 'default'
            };
        });

    alert(title || '', description || '', formattedOptions);
}
