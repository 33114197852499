import React from 'react';
import { View, Text, Dimensions, StyleSheet } from 'react-native';

const { width } = Dimensions.get('window');

const DriverItem = ({ item }) => {
    const isSmallScreen = width < 600; // Adjust the breakpoint as needed

    return (
        <View style={[styles.container, isSmallScreen && styles.smallScreenContainer]}>
            <Text style={[styles.text, styles.header]}>Şöför Bilgisi</Text>
            <Text style={styles.text}>Şöför Adı: {item.full_name}</Text>
            <Text style={styles.text}>Eposta: {item.user_email}</Text>
            {/* <Text style={styles.text}>Looking for Car: {item.isLookingForCar}</Text> */}
            <Text style={styles.text}>Sahip Olduğu Ehliyetler: {item.drivingLicenseTypes.join(', ')}</Text>
            <Text style={styles.text}>Şehir: {item.city}</Text>
            {/* <Text style={styles.text}>Birthday: {item.birthday}</Text> */}
            {/* Add more driver details as needed */}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        backgroundColor: 'white',
        borderRadius: 8,
        marginBottom: 16,
        padding: 16,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
    },
    smallScreenContainer: {
        width: '100%', // Adjust to make it full-width on small screens
    },
    header: {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 12,
    },
    text: {
        fontSize: 16,
        marginBottom: 8,
    },
});

export default DriverItem;
