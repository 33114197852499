import React, { useContext } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { PaperProvider } from 'react-native-paper';
import LoginScreen from './screens/AuthStack/LoginScreen';
import RegisterScreen from './screens/AuthStack/RegisterScreen';
import LogoutScreen from './screens/AuthStack/LogoutScreen';
import DriverDashboardScreen from './screens/DriverStack/DriverDashboardScreen';
import DriverProfileScreen from './screens/DriverStack/DriverProfileScreen';
import DriverSettingsScreen from './screens/DriverStack/DriverSettingsScreen';
import SearchVehiclesScreen from './screens/DriverStack/SearchVehiclesScreen';
import CompanyDashboardScreen from './screens/CompanyStack/CompanyDashboardScreen';
import CompanyProfileScreen from './screens/CompanyStack/CompanyProfileScreen';
import CompanySettingsScreen from './screens/CompanyStack/CompanySettingsScreen';
import UserContext, { UserProvider } from './contexts/UserContext';
import CompanyVehiclesScreen from './screens/CompanyStack/CompanyVehiclesScreen';
import { Platform } from 'react-native';
import Cookies from 'js-cookie';
import SearchDriversScreen from './screens/CompanyStack/SearchDriversScreen';

// Login, Register ve Logout ekranlarını içeren LoginStack
const LoginStack = createStackNavigator();

const LoginStackScreens = () => (
  <LoginStack.Navigator>
    <LoginStack.Screen name="Login" component={LoginScreen}
      options={{
        title: 'Giriş Yap',
      }}
    />
    {/* <LoginStack.Screen name="Login" component={CompanySettingsScreen} /> */}
    <LoginStack.Screen name="Register" component={RegisterScreen}
      options={{
        title: 'Kayıt Ol',
      }}
    />
    <LoginStack.Screen name="Logout" component={LogoutScreen}
      options={{
        title: 'Çıkış Yap',
      }}
    />
  </LoginStack.Navigator>
);

// Şoför ile ilgili ekranları içeren ŞoförStack
const SoforStack = createStackNavigator();

const SoforStackScreens = () => (
  <SoforStack.Navigator>
    <SoforStack.Screen name="DriverDashboard" component={DriverDashboardScreen}
      options={{
        title: 'Kaptak Köşkü',
      }}
    />
    <SoforStack.Screen name="SearchVehiclesScreen" component={SearchVehiclesScreen}
      options={{
        title: 'Araç Ara',
      }}
    />
    <SoforStack.Screen name="DriverProfile" component={DriverProfileScreen}
      options={{
        title: 'Profil',
      }}
    />
    <SoforStack.Screen name="DriverSettings" component={DriverSettingsScreen}
      options={{
        title: 'Ayarlar',
      }}
    />
  </SoforStack.Navigator>
);

// Firma ile ilgili ekranları içeren FirmaStack
const FirmaStack = createStackNavigator();

const FirmaStackScreens = () => (
  <FirmaStack.Navigator>
    <FirmaStack.Screen name="CompanyDashboard" component={CompanyDashboardScreen}
      options={{
        title: 'Operasyon Paneli',
      }}
    />
    <FirmaStack.Screen name="SearchDriversScreen" component={SearchDriversScreen}
      options={{
        title: 'Şöför Ara',
      }}
    />
    <FirmaStack.Screen name="CompanyVehiclesScreen" component={CompanyVehiclesScreen}
      options={{
        title: 'Şirket Araçları',
      }}
    />
    <FirmaStack.Screen name="CompanyProfile" component={CompanyProfileScreen}
      options={{
        title: 'Şirket Profili',
      }}
    />
    <FirmaStack.Screen name="CompanySettings" component={CompanySettingsScreen}
      options={{
        title: 'Şirket Ayarları',
      }}
    />
  </FirmaStack.Navigator>
);



export const MainApp = () => {
  const { user, setLoginStatus } = useContext(UserContext);
  const userRole = user.role; // Set the user's role here
  const isLoggedIn = user.isLoggedIn; // Set the login status here



  // Check if the app is running on a mobile device
  if (Platform.OS !== 'android' && Platform.OS !== 'ios') {
    // Set a cookie
    const token = Cookies.get('token');
    if (token) {
      // The "token" cookie is set
      console.log('Token is set:', token);
      //setLoginStatus(true); // Set the login status to true here
    } else {
      // The "token" cookie is not set
      console.log('Token is not set.');
    }
  } else {
    console.log('This code is running on a mobile device.');
  }

  return (
    <NavigationContainer
      linking={{
        prefixes: ['https://panel.kaptanbul.com'],
        config: {
          screens: {
            Login: 'login',
            Register: 'register',
            Logout: 'logout',
            DriverDashboard: 'driver-dashboard',
            DriverProfile: 'driver-profile',
            DriverSettings: 'driver-settings',
            SearchVehiclesScreen: 'search-vehicles',
            CompanyDashboard: 'company-dashboard',
            CompanyProfile: 'company-profile',
            CompanySettings: 'company-settings',
            CompanyVehiclesScreen: 'company-vehicles',
          }
        }
      }}     >
      {
        isLoggedIn ? (
          userRole === 'driver' ? (
            <SoforStackScreens />
          ) : (
            <FirmaStackScreens />
          )
        ) : (
          <LoginStackScreens />
        )
      }
    </NavigationContainer >
  );
};


const App = () => {
  return (
    <PaperProvider>
      <UserProvider>
        <MainApp />
      </UserProvider>
    </PaperProvider>
  );
};

export default App;