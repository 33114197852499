import React, { useContext, useEffect, useState } from 'react';
import {
    View,
    Text,
    TouchableOpacity,
    StyleSheet,
    ScrollView,
    Modal,
    TextInput,
    ActivityIndicator,
} from 'react-native';
import UserContext from '../../contexts/UserContext';

const CompanyVehiclesScreen = () => {
    const { user, api } = useContext(UserContext);
    const [vehicles, setVehicles] = useState([{
        "brand": "Yükleniyor...",
        "model": "Yükleniyor...",
        "year": "Yükleniyor...",
        "vehicleType": "Yükleniyor...",
        "transmissionType": "Yükleniyor...",
        "location": "Yükleniyor...",
        "salaryOffer": "Yükleniyor..."
    }
    ]);
    const [isLoading, setLoading] = useState(true);
    useEffect(() => {
        console.log(typeof vehicles);
        console.log(vehicles);

    }, [vehicles])


    function loadVehicles() {
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');

        const raw = JSON.stringify({
            token: user.token,
        });

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow',
        };

        setLoading(true);
        fetch(api.url + 'vehicles/list', requestOptions)
            .then(response => response.json())
            .then(result => {
                setVehicles(result);
                setLoading(false);
                console.log(result);
            })
            .catch(error => {
                console.log('error', error);
                setLoading(false);
            });

        return () => { };
    }

    useEffect(() => {
        loadVehicles();
    }, []);

    const [isModalVisible, setModalVisible] = useState(false);
    const [newVehicle, setNewVehicle] = useState({
        brand: '',
        model: '',
        year: '',
        vehicleType: '',
        transmissionType: '',
        location: '',
        salaryOffer: '',
    });

    const handleDeleteVehicle = vehicleId => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "token": user.token,
            "post_id": vehicleId
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        setLoading(true);
        fetch(api.url + "vehicles/delete", requestOptions)
            .then(response => response.json())
            .then(result => loadVehicles())
            .then(() => { setLoading(false) })
            .catch(error => console.log('error', error));
    };

    const handleAddVehicle = () => {
        setModalVisible(true);
    };
    const handleSaveVehicle = () => {
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');

        const raw = JSON.stringify({
            token: user.token,
            brand: newVehicle.brand,
            model: newVehicle.model,
            year: newVehicle.year,
            vehicleType: newVehicle.vehicleType,
            transmissionType: newVehicle.transmissionType,
            location: newVehicle.location,
            salaryOffer: newVehicle.salaryOffer,
        });
        console.log(raw);

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow',
        };

        setLoading(true);
        fetch(api.url + 'vehicles/save', requestOptions)
            .then(response => response.text())
            .then(result => {
                console.log(result);
                setNewVehicle({
                    brand: '',
                    model: '',
                    year: '',
                    vehicleType: '',
                    transmissionType: '',
                    location: '',
                    salaryOffer: '',
                });
                setModalVisible(false);
                setLoading(false);
                loadVehicles();
            })
            .catch(error => {
                console.log('error', error);
                setLoading(false);
            });
    };

    const handleCancelAddVehicle = () => {
        setNewVehicle({
            brand: '',
            model: '',
            year: '',
            vehicleType: '',
            transmissionType: '',
            location: '',
            salaryOffer: '',
        });
        setModalVisible(false);
    };

    return (
        <View style={styles.container}>
            <Text style={styles.title}>Şirket Araçlarım</Text>
            <ScrollView>
                {isLoading ? (
                    <View style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        zIndex: 9999,
                        backgroundColor: "rgba(0, 0, 0, 0.5)",  // Optional: Add a semi-transparent background
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <ActivityIndicator size="large" color="#000000" />
                    </View>
                ) : (
                    <View style={styles.table}>
                        <View style={styles.tableHeader}>
                            <Text style={styles.columnHeader}>Marka</Text>
                            <Text style={styles.columnHeader}>Model</Text>
                            <Text style={styles.columnHeader}>Yıl</Text>
                            <Text style={styles.columnHeader}>Tip</Text>
                            <Text style={styles.columnHeader}>Vites Tipi</Text>
                            <Text style={styles.columnHeader}>Lokasyon</Text>
                            <Text style={styles.columnHeader}>Ücret Teklifi</Text>
                            <Text style={styles.columnHeader}></Text>
                        </View>
                        {vehicles.map(vehicle => (
                            <View style={styles.tableRow} key={vehicle.id}>
                                <Text style={styles.tableData}>{vehicle.brand}</Text>
                                <Text style={styles.tableData}>{vehicle.model}</Text>
                                <Text style={styles.tableData}>{vehicle.year}</Text>
                                <Text style={styles.tableData}>{vehicle.vehicleType}</Text>
                                <Text style={styles.tableData}>{vehicle.transmissionType}</Text>
                                <Text style={styles.tableData}>{vehicle.location}</Text>
                                <Text style={styles.tableData}>{vehicle.salaryOffer}</Text>
                                <TouchableOpacity
                                    style={styles.deleteButton}
                                    onPress={() => handleDeleteVehicle(vehicle.id)}
                                >
                                    <Text style={styles.deleteButtonText}>Sil</Text>
                                </TouchableOpacity>
                            </View>
                        ))}
                    </View>
                )}
            </ScrollView>
            <TouchableOpacity style={styles.addButton} onPress={handleAddVehicle}>
                <Text style={styles.addButtonText}>Araç Ekle</Text>
            </TouchableOpacity>
            <Modal visible={isModalVisible} animationType="slide" transparent>
                <View style={styles.modalContainer}>
                    <View style={styles.modalContent}>
                        <Text style={styles.modalTitle}>Yeni Araç</Text>
                        <TextInput
                            style={styles.modalInput}
                            placeholder="Marka"
                            value={newVehicle.brand}
                            onChangeText={text => setNewVehicle({ ...newVehicle, brand: text })}
                        />
                        <TextInput
                            style={styles.modalInput}
                            placeholder="Model"
                            value={newVehicle.model}
                            onChangeText={text => setNewVehicle({ ...newVehicle, model: text })}
                        />
                        <TextInput
                            style={styles.modalInput}
                            placeholder="Yıl"
                            value={newVehicle.year}
                            onChangeText={text => setNewVehicle({ ...newVehicle, year: text })}
                        />
                        <TextInput
                            style={styles.modalInput}
                            placeholder="Araç Tipi"
                            value={newVehicle.vehicleType}
                            onChangeText={text =>
                                setNewVehicle({ ...newVehicle, vehicleType: text })
                            }
                        />
                        <TextInput
                            style={styles.modalInput}
                            placeholder="Vites Tipi"
                            value={newVehicle.transmissionType}
                            onChangeText={text =>
                                setNewVehicle({ ...newVehicle, transmissionType: text })
                            }
                        />
                        <TextInput
                            style={styles.modalInput}
                            placeholder="Lokasyon"
                            value={newVehicle.location}
                            onChangeText={text => setNewVehicle({ ...newVehicle, location: text })}
                        />
                        <TextInput
                            style={styles.modalInput}
                            placeholder="Ücret Teklifi"
                            value={newVehicle.salaryOffer}
                            onChangeText={text =>
                                setNewVehicle({ ...newVehicle, salaryOffer: text })
                            }
                        />
                        <View style={styles.modalButtons}>

                            <TouchableOpacity
                                style={styles.modalButton}
                                onPress={handleCancelAddVehicle}
                            >
                                <Text style={styles.modalButtonText}>İptal</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.modalButton} onPress={handleSaveVehicle}>
                                <Text style={styles.modalButtonText}>Kaydet</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            </Modal>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#f5f5f5',
        padding: 16,
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 20,
    },
    table: {
        borderWidth: 1,
        borderColor: '#dddddd',
        borderRadius: 8,
        overflow: 'hidden',
        marginBottom: 16,
    },
    tableHeader: {
        backgroundColor: '#f5f5f5',
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderColor: '#dddddd',
        padding: 10,
    },
    columnHeader: {
        flex: 1,
        fontWeight: 'bold',
    },
    tableRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderColor: '#dddddd',
        padding: 10,
    },
    tableData: {
        flex: 1,
    },
    deleteButton: {
        backgroundColor: '#dc3545',
        paddingVertical: 8,
        paddingHorizontal: 16,
        borderRadius: 8,
        justifyContent: 'center',
        alignItems: 'center',
    },
    deleteButtonText: {
        color: '#ffffff',
        fontSize: 14,
        fontWeight: 'bold',
    },
    addButton: {
        backgroundColor: '#28a745',
        paddingVertical: 12,
        paddingHorizontal: 16,
        borderRadius: 8,
        alignItems: 'center',
        justifyContent: 'center',
    },
    addButtonText: {
        color: '#ffffff',
        fontSize: 16,
        fontWeight: 'bold',
    },
    modalContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContent: {
        backgroundColor: '#ffffff',
        borderRadius: 8,
        padding: 16,
        width: '80%',
    },
    modalTitle: {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 16,
        textAlign: 'center',
    },
    modalInput: {
        backgroundColor: '#f5f5f5',
        padding: 8,
        marginBottom: 16,
        borderRadius: 8,
    },
    modalButtons: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    modalButton: {
        backgroundColor: '#007bff',
        paddingVertical: 8,
        paddingHorizontal: 16,
        borderRadius: 8,
        flex: 0.49,
        marginHorizontal: 4,
    },
    modalButtonText: {
        color: '#ffffff',
        fontSize: 14,
        fontWeight: 'bold',
        textAlign: 'center',
    },
});

export default CompanyVehiclesScreen;
