import React, { useEffect, useState } from 'react';
import { View, Text, TextInput, TouchableOpacity, FlatList, StyleSheet, ActivityIndicator } from 'react-native';
import CustomButton from '../../components/CustomButton';

import fetchDrivers from '../../api/fetchDrivers';
import DriverItem from '../../components/DriverItem';
import customAlert from '../../components/Alert';

const SearchDriversScreen = () => {
    const [drivers, setDrivers] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [searchParams, setSearchParams] = useState({
        isLookingForCar: '',
        drivingLicenseTypes: '',
        phoneNumber: '',
        city: '',
        district: '',
        birthday: '',
        logo: '',
        page: 1,
    });

    const inputFields = [
        // { key: 'isLookingForCar', label: 'Looking for Car' },
        { key: 'drivingLicenseTypes', label: 'License Types' },
        // { key: 'phoneNumber', label: 'Phone Number' },
        { key: 'city', label: 'City' },
        // { key: 'district', label: 'District' },
        // { key: 'birthday', label: 'Birthday' },
        // { key: 'logo', label: 'Logo' },
    ];

    const searchDriversHandler = async (search = false) => {
        let tempdrivers = await fetchDrivers({ ...searchParams, page });
        if (tempdrivers.length === 0) {
            setLoading(false);
            customAlert('Sürücü Bulunamadı');
            console.log('Sürücü Bulunamadı');
        }
        if (search) {
            setDrivers([...tempdrivers]);
        } else {
            setDrivers([...drivers, ...tempdrivers]);
        }
    }

    useEffect(() => {
        searchDriversHandler();
    }, [page]);

    useEffect(() => {
        console.log(drivers);
        if (drivers.length > 0) {
            setLoading(false);
            console.log(loading);
        }
        // if (drivers.length === 0) {
        //     setLoading(false);
        //     Alert.alert('Sürücü Bulunamadı');
        // }
    }, [drivers]);

    const handleInputChange = (valueKey, value) => {
        setSearchParams({ ...searchParams, [valueKey]: value });
    };

    const handleSearch = () => {
        setPage(1); // Reset page to 1 when initiating a new search        
        setLoading(true);
        searchDriversHandler(true);
    };

    const renderItem = ({ item }) => (

        < DriverItem item={item} />
    );
    if (loading) return <>
        <Text>Loading...</Text>
        <ActivityIndicator size="large" color="#0000ff" />
    </>;
    return (
        <View style={styles.container}>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                {inputFields.map((field) => (
                    <View key={field.key} style={styles.inputContainer}>
                        <Text>{field.label}:</Text>
                        <TextInput
                            style={styles.input}
                            value={searchParams[field.key]}
                            onChangeText={(text) => handleInputChange(field.key, text)}
                        />
                    </View>
                ))}

                <TouchableOpacity style={styles.button} onPress={() => handleSearch()}>
                    <Text style={styles.buttonText}>Search</Text>
                </TouchableOpacity>
            </View>
            {drivers.length === 0 ? (
                <Text>No drivers found.</Text>) : <></>}
            <FlatList
                data={drivers}
                renderItem={renderItem}
                keyExtractor={(item) => item.ID.toString()}
                onEndReached={() => {
                    if (drivers.length > 9)
                        // Load more drivers when reaching the end of the list
                        setPage(page + 1);
                }}
                onEndReachedThreshold={0.2}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 16,
    },
    inputContainer: {
        marginBottom: 16,
        marginHorizontal: 10,
    },
    input: {
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 5,
        padding: 8,
    },
    button: {
        backgroundColor: '#007AFF',
        paddingHorizontal: 20,
        borderRadius: 5,
        alignItems: 'center',
        height: 30,
        justifyContent: 'center',
    },
    buttonText: {
        color: 'white',
        fontSize: 16,
        fontWeight: 'bold',
    },
});

export default SearchDriversScreen;
