import React from 'react';
import { View, Text } from 'react-native';

const LogoutScreen = () => {
    return (
        <View>
            <Text>Logout Screen</Text>
            {/* Logout ekranına ait diğer bileşenler */}
        </View>
    );
};

export default LogoutScreen;
