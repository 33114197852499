import React, { useEffect, useState } from 'react';
import { View, Text, TextInput, TouchableOpacity, FlatList, StyleSheet } from 'react-native';
import CustomButton from '../../components/CustomButton';
import VehicleItem from '../../components/VehicleItem';
import { searchVehicles } from '../../api/searchVehicles';


const SearchVehiclesScreen = () => {
    const [vehicles, setVehicles] = useState([
        {
            "id": "Yükleniyor",
            "title": "Yükleniyor",
            "content": "Yükleniyor",
            "brand": "Yükleniyor",
            "model": "Yükleniyor",
            "year": "Yükleniyor",
            "vehicleType": "Yükleniyor",
            "transmissionType": "Yükleniyor",
            "location": "Yükleniyor",
            "salaryOffer": "Yükleniyor",
            "contact_phone": "Yükleniyor",
            "contact_email": "Yükleniyor",
        }
    ]);
    const [page, setPage] = useState(1);
    const [searchParams, setSearchParams] = useState({
        brand: '',
        model: '',
        year: '',
        vehicle_type: '',
        transmission_type: '',
        location: '',
        page: 1,
    });

    const inputFields = [
        { key: 'brand', label: 'Marka' },
        { key: 'model', label: 'Model' },
        { key: 'year', label: 'Yıl', keyboardType: 'numeric' },
        // Add more filter inputs for other search parameters
    ];

    const fetchVehicles = async () => {
        // Your fetch logic here...
        let vehicleTemp = await searchVehicles({ ...searchParams, page: page });
        //  remove object from list with id "id": "Yükleniyor", 
        //  because it's not a real vehicle.
        let vehiclenonloadingTemp = vehicles.filter(vehicle => vehicle.id !== "Yükleniyor");
        setVehicles([...vehiclenonloadingTemp, ...vehicleTemp.results]);
    };


    useEffect(() => {
        // console.log("fired", vehicles);
        fetchVehicles(searchParams);
    }, []);

    const handleInputChange = (valueKey, value) => {
        let tempVal = { ...searchParams };
        tempVal[valueKey] = value;
        setSearchParams(tempVal);
    };

    const handleSearch = async () => {
        setPage(1); // Reset page to 1 when initiating a new search
        let vehiclaTemp = await searchVehicles({ ...searchParams, page: 1 })
        setVehicles(vehiclaTemp.results);
    };

    const renderItem = ({ item }) => (
        <VehicleItem item={item}></VehicleItem>
    );

    return (
        <View style={styles.container}>
            <View
                style={{
                    flexDirection: 'row',
                    alignContent: "center",
                    justifyContent: "center",
                }}
            >
                {inputFields.map((field) => (
                    <View key={field.key} style={styles.inputContainer}>
                        <Text>{field.label}:</Text>
                        <TextInput
                            style={styles.input}
                            value={searchParams[field.key]}
                            onChangeText={(text) => handleInputChange(field.key, text)}
                            keyboardType={field.keyboardType || 'default'}
                        />
                    </View>
                ))}

                <View
                    style={{
                        flexDirection: "col",
                        alignContent: "center",
                        justifyContent: "center",
                    }}
                >
                    <CustomButton title="Ara" onPress={() => handleSearch()} />
                </View>
            </View>
            <FlatList
                data={vehicles}
                renderItem={renderItem}
                keyExtractor={(item) => item.id}
                onEndReached={() => {
                    if (vehicles.length > 10) {
                        // Load more vehicles when reaching the end of the list
                        setPage(page + 1);
                        fetchVehicles();
                    }
                }}
                onEndReachedThreshold={0.2}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 16,
    },
    inputContainer: {
        marginBottom: 16,
        marginHorizontal: 10,
    },
    input: {
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 5,
        padding: 8,
    },
    button: {
        backgroundColor: '#007AFF', // Adjust the background color as desired
        paddingHorizontal: 20,
        borderRadius: 5,
        alignItems: 'center',
        height: 30
    },
    buttonText: {
        color: 'white',
        fontSize: 16,
        fontWeight: 'bold',
    },
});

export default SearchVehiclesScreen;
