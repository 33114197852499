import React, { createContext, useEffect, useState } from 'react';
import { Platform } from 'react-native';
import Cookies from 'js-cookie';
import { apiBase } from '../api/constants';
const UserContext = createContext(null);

const url = apiBase + "/wp-json/eliyteSurfer/v1/"

const getProfile = (token, id) => {
  return new Promise((resolve, reject) => {
    try {
      // Perform the API request to fetch user details
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "token": token,
        "user_id": id
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(url + "profile/get", requestOptions)
        .then(response => {
          if (!response.ok) {
            throw new Error('Failed to fetch user details');
          }
          return response.json();
        })
        .then(data => {
          resolve(data); // Resolve the Promise with the fetched data
        })
        .catch(error => {
          reject(error); // Reject the Promise with the error
        });
    } catch (error) {
      reject(error); // Reject the Promise if an exception occurs
    }
  });
};



export const UserProvider = ({ children }) => {

  const [user, setUser] = useState({
    // role: "driver", // Set the user's role here
    //role: "firma", // Set the user's role here
    isLoggedIn: false, // Set the login status here
    id: false,
    token: false, // Set the login status here   
    getProfile: getProfile,
  });
  const [api, setApi] = useState({
    url: url
  })
  const LogMeOut = () => {

    // Check if the app is running on a mobile device
    if (Platform.OS !== 'android' && Platform.OS !== 'ios') {
      // Remove the token and user data from the local cookies
      Cookies.remove('token');
      Cookies.remove('user');
    } else {
      console.log('This code is running on a mobile device.');
    }
    setUser((prevUser) => ({
      ...prevUser,
      isLoggedIn: false,
      token: false,
      role: false
    }));
  };
  const setLoginStatus = (isLoggedIn) => {
    setUser((prevUser) => ({
      ...prevUser,
      isLoggedIn: isLoggedIn,
    }));
  };
  const setToken = (token) => {
    setUser((prevUser) => ({
      ...prevUser,
      token: token,
    }));
  };
  const setUserid = (userid) => {
    setUser((prevUser) => ({
      ...prevUser,
      id: userid,
    }));
  };
  const setUserRole = (role) => {
    setUser((prevUser) => ({
      ...prevUser,
      role: role,
    }));
  };

  useEffect(() => {
    console.log(user)

    return () => {
      "second"
    }
  }, [user])


  return (
    <UserContext.Provider value={{ user, setToken, setLoginStatus, LogMeOut, api, setUserid, setUserRole }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
