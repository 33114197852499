import React, { useState } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import BabyBlueInput from '../../components/BabyBlueInput';
import CustomButton from '../../components/CustomButton';
import customAlert from '../../components/Alert';
import { apiBase } from '../../api/constants';

const RegisterScreen = ({ navigation }) => {
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const handleRegister = async () => {
        // Field validation logic
        if (name === '' || phoneNumber === '' || email === '' || password === '' || confirmPassword === '') {
            customAlert('Error', 'Lütfen tüm alanları doldurunuz');
            return;
        }

        try {
            // Create the user registration data object
            const userData = {
                name,
                phone: phoneNumber,
                email,
                password,
                confirmPassword,
                role: 'Driver', // You can set the role here
            };

            // Send a POST request to your backend endpoint (api.kaptanbul.com)
            const response = await fetch(apiBase + '/wp-json/eliyteSurfer/v1/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userData),
            });

            // Check if registration was successful
            const data = await response.json();
            if (response.ok && data.token) {
                // Registration was successful, handle your logic here
                customAlert('Success', 'Kayıt başarılı');
                navigation.navigate('Login'); // Navigate to the login screen after successful registration
                // You can navigate to the login screen or any other screen here
            } else {
                // Registration failed, display an error message
                customAlert('Error', 'Kayıt başarısız');
            }
        } catch (error) {
            // Handle any error that occurred during the fetch request
            console.error(error);
            customAlert('Error', 'Kayıt işlemi sırasında bir hata oluştu');
        }
    };


    return (
        <View style={styles.container}>
            <View style={styles.border}>
                <Text style={styles.title}>Hesap Oluşturun</Text>
                <Text style={styles.body}>Şirket veya Kaptan hesabı oluşturun</Text>

                <BabyBlueInput
                    label="Adınız"
                    placeholder="Adınız"
                    onChangeText={text => setName(text)}
                />

                <BabyBlueInput
                    label="Soyadınız"
                    placeholder="Soyadınız"
                    onChangeText={text => setName(prevName => prevName + ' ' + text)}
                />

                <BabyBlueInput
                    label="Telefon numaranız"
                    placeholder="Telefon numaranız"
                    onChangeText={text => setPhoneNumber(text)}
                />

                <BabyBlueInput
                    label="Eposta Adresiniz"
                    placeholder="Eposta Adresiniz"
                    onChangeText={text => setEmail(text)}
                />

                <BabyBlueInput
                    label="Şifreniz"
                    placeholder="Şifreniz"
                    secureTextEntry
                    onChangeText={text => setPassword(text)}
                />

                <BabyBlueInput
                    label="Şifreniz Tekrar"
                    placeholder="Şifreniz Tekrar"
                    secureTextEntry
                    onChangeText={text => setConfirmPassword(text)}
                />

                <View style={styles.buttonContainer}>
                    <CustomButton title="Kayıt Ol" onPress={handleRegister} />
                </View>

                <View style={styles.buttonContainer}>
                    <CustomButton title="Giriş Yap" onPress={() => { navigation.navigate('Login') }} />
                </View>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#F5F5F5',
    },
    border: {
        width: '90%',
        padding: 20,
        backgroundColor: '#FFFFFF',
        borderRadius: 10,
        borderWidth: 1,
        borderColor: '#E2E2E2',
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 20,
        textAlign: 'center',
    },
    body: {
        marginBottom: 20,
        textAlign: 'center',
    },
    buttonContainer: {
        marginTop: 10,
    },
});

export default RegisterScreen;
