import React, { useState, useContext, useEffect } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { Switch } from 'react-native-paper';
import { Checkbox } from 'react-native-paper';
import BabyBlueInput from '../../components/BabyBlueInput';
import CustomButton from '../../components/CustomButton';
import UserContext from '../../contexts/UserContext';
import customAlert from '../../components/Alert';

const licenseTypes = [
    { label: 'A', value: 'A' },
    { label: 'C1', value: 'C1' },
    { label: 'C', value: 'C' },
    { label: 'D1', value: 'D1' },
    { label: 'D', value: 'D' },
    { label: 'BE', value: 'BE' },
    { label: 'CE', value: 'CE' },
    { label: 'C1E', value: 'C1E' },
    { label: 'DE', value: 'DE' },
    { label: 'D1E', value: 'D1E' },
    { label: 'M', value: 'M' },
    { label: 'B1', value: 'B1' },
    { label: 'B', value: 'B' },
    { label: 'A1', value: 'A1' },
    { label: 'A2', value: 'A2' },
    { label: 'F', value: 'F' },
    { label: 'G', value: 'G' },
];

const DriverSettingsScreen = () => {
    const { user, api } = useContext(UserContext);
    const [isLookingForCar, setIsLookingForCar] = useState(false);
    const [fullName, setFullName] = useState('');
    const [drivingLicenseTypes, setDrivingLicenseTypes] = useState([]);
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [city, setCity] = useState('');
    const [district, setDistrict] = useState('');
    const [age, setAge] = useState('');

    const handleSaveSettings = async () => {
        const url = api.url + "update-user-profile"; // Replace with the actual endpoint URL
        const requestBody = {
            token: user.token,
            fullname: fullName,
            isLookingForCar,
            drivingLicenseTypes,
            phoneNumber,
            city,
            district,
            age
        };

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
            redirect: 'follow',
        };

        let response = await fetch(url, requestOptions);
        if (response.ok) {
            console.log('Oldu tamam');
            customAlert("Başarılı", "Ayarlar başarıyla kaydedildi.")

        } else {
            customAlert("Hata", "Ayarlar kaydedilemedi. Tekrar deneyiniz.");
        }
    };

    // on driverlisence types change console log 
    useEffect(() => {
        console.log(drivingLicenseTypes);
    }, [drivingLicenseTypes]);

    // fetch user details
    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                // Fetch user details when the page is updated        
                const response = await user.getProfile(user.token, user.id);
                console.table(response);
                const userData = response;
                // Update your component's state with the fetched user data
                setIsLookingForCar(userData.isLookingForCar);
                setFullName(userData.fullname);
                setCity(userData.city);
                setDistrict(userData.district);
                setPhoneNumber(userData.phoneNumber);
                setEmail(userData.email);
                // for each userdata drivinglicensetypes run hanlelicensetypechange
                userData.drivingLicenseTypes.forEach(type => {
                    console.log(type);

                    handleLicenseTypeChange(type);
                });
            } catch (error) {
                console.log('Error fetching user details:', error);
            }
        };

        fetchUserDetails();
    }, []);


    const toggleLookingForCar = (value) => {
        setIsLookingForCar(value);
    };

    const handleLicenseTypeChange = (type) => {
        setDrivingLicenseTypes((types) =>
            types.includes(type)
                ? types.filter((t) => t !== type)
                : [...types, type]
        );
    };



    return (
        <View style={styles.container}>
            <View style={styles.formContainer}>
                <Text style={styles.title}>Sürücü Ayarları</Text>
                <View style={styles.fieldContainer}>
                    <Text style={styles.label}>Araç Arıyorum Durumu:</Text>
                    <Switch
                        value={isLookingForCar}
                        onValueChange={toggleLookingForCar}
                    />
                </View>

                <BabyBlueInput
                    label="Ad Soyad"
                    placeholder="Ad Soyad"
                    value={fullName}
                    onChangeText={setFullName}
                />

                <View style={styles.fieldContainer}>
                    <Text style={styles.label}>Ehliyet Tipi:</Text>
                    <View style={styles.checkboxContainer}>
                        {licenseTypes.map((type) => (
                            <View key={type.value} style={styles.checkbox}>
                                <Checkbox.Item
                                    label={type.label}
                                    status={
                                        drivingLicenseTypes.includes(type.value)
                                            ? 'checked'
                                            : 'unchecked'
                                    }
                                    color="#00A8E8"
                                    uncheckedColor="#00A8E8"
                                    labelStyle={styles.label}
                                    onPress={() => handleLicenseTypeChange(type.value)}
                                />
                            </View>
                        ))}
                    </View>
                </View>

                <BabyBlueInput
                    label="Eposta"
                    placeholder="Eposta"
                    value={email}
                    onChangeText={setEmail}
                />

                <BabyBlueInput
                    label="Telefon"
                    placeholder="Telefon"
                    value={phoneNumber}
                    onChangeText={setPhoneNumber}
                />

                <BabyBlueInput
                    label="İl"
                    placeholder="İl"
                    value={city}
                    onChangeText={setCity}
                />

                <BabyBlueInput
                    label="İlçe"
                    placeholder="İlçe"
                    value={district}
                    onChangeText={setDistrict}
                />

                <BabyBlueInput
                    label="Yaş"
                    placeholder="Yaş"
                    value={age}
                    onChangeText={setAge}
                />

                <View style={styles.buttonContainer}>
                    <CustomButton title="Profili Kaydet" onPress={handleSaveSettings} />
                </View>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    formContainer: {
        width: '50%',
        padding: 20,
        borderRadius: 10,
        backgroundColor: '#fff',
        elevation: 3,
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 20,
    },
    fieldContainer: {
        marginBottom: 10,
    },
    label: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#00A8E8',
    },
    checkboxContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '100%',
    },
    checkbox: {

    },
    buttonContainer: {
        marginTop: 10,
    },
});

export default DriverSettingsScreen;
