import React, { useContext, useEffect, useState } from 'react';
import { View, Text } from 'react-native';
import BabyBlueInput from '../../components/BabyBlueInput';
import CustomButton from '../../components/CustomButton';
import UserContext from '../../contexts/UserContext';
import { Platform } from 'react-native';
import Cookies from 'js-cookie';


const LoginScreen = ({ navigation }) => {
    const { setLoginStatus, setToken, setUserid, setUserRole, api } = useContext(UserContext)
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')


    useEffect(() => {


        // Check if the app is running on a mobile device
        if (Platform.OS !== 'android' && Platform.OS !== 'ios') {
            // Set a cookie
            const token = Cookies.get('token');
            const user = Cookies.get('user');
            if (token && user) {

                // encode str to object
                const objectUser = JSON.parse(user);
                // The "token" cookie is set
                console.log('Token is set:', token);
                console.log('User is set:', objectUser);
                setToken(objectUser.token);
                setUserid(objectUser.userID);
                setLoginStatus(true);
                setUserRole(objectUser.roles[0]);
                //setLoginStatus(true); // Set the login status to true here
            } else {
                // The "token" cookie is not set
                console.log('Token is not set.');
            }
        } else {
            console.log('This code is running on a mobile device.');
        }


    }, [])



    const handleLogin = () => {
        // Giriş yapma işlemleri

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "username": username,
            "password": password
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(api.url + "login", requestOptions)
            .then(response => response.json())
            .then((result) => {
                if (result.token) {
                    console.log('rselam');
                    setToken(result.token);
                    setUserid(result.userID);
                    setLoginStatus(true);
                    setUserRole(result.roles[0]);
                    // Check if the app is running on a mobile device
                    if (Platform.OS !== 'android' && Platform.OS !== 'ios') {
                        // Set a cookie
                        Cookies.set('token', result.token);
                        var strResult = JSON.stringify(result);
                        Cookies.set('user', strResult);
                        console.log('Cookie set successfully');
                    } else {
                        console.log('This code is running on a mobile device.');
                    }
                }
            })
            .catch(error => console.log('error', error));
    };
    const handleRegister = () => {
        // Giriş yapma işlemleri
        navigation.navigate('Register')
    };

    return (
        <View style={styles.container}>
            <View
                style={{
                    backgroundColor: "#ffffff",
                    padding: 30,
                    borderRadius: 27,
                }}
            >
                <View
                    style={{
                        marginVertical: 20,
                    }}
                >
                    <Text style={styles.title}>Kaptanbul'a Hoş Geldiniz</Text>
                </View>
                <View
                    style={{}}
                >
                    <BabyBlueInput
                        label="Kullanıcı Adı"
                        placeholder="Kullanıcı Adı"
                        onChangeText={(text) => setUsername(text)}
                    />

                    <BabyBlueInput
                        label="Şifre"
                        placeholder="Şifre"
                        secureTextEntry
                        onChangeText={(text) => setPassword(text)}
                    />
                </View>
                <View style={{
                    flexDirection: "row",
                }}>
                    <View style={{
                        flex: 1,
                        flexDirection: "row",
                        marginTop: 50
                    }} >
                        <CustomButton title="Hesap Oluştur" onPress={handleRegister} />
                        <CustomButton title="Giriş Yap" onPress={handleLogin} />
                    </View>
                </View>
            </View>
        </View>
    );
};

const styles = {
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 20,
        height: "100%",
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 20,
    },
};

export default LoginScreen;
