import React, { useContext } from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import UserContext from '../../contexts/UserContext';

const CompanyDashboardScreen = () => {
    const navigation = useNavigation();
    const { LogMeOut } = useContext(UserContext)

    const navigateToSoForArama = () => {
        navigation.navigate('SearchDriversScreen');
    };

    const navigateToAraçlarım = () => {
        navigation.navigate('CompanyVehiclesScreen');
    };

    // const navigateToGelenTeklifler = () => {
    //     navigation.navigate('GelenTekliflerScreen');
    // };

    const navigateToProfilAyarları = () => {
        navigation.navigate('CompanySettings');
    };

    const navigateToÇıkışYap = () => {
        LogMeOut();
    };

    return (
        <View style={styles.container}>
            <View
                style={{
                    backgroundColor: "#ffffff",
                    padding: 30,
                    borderRadius: 27,
                }}
            >
                <Text style={styles.text}>Şirket Operasyon Paneli</Text>
                <View style={styles.buttonContainer}>
                    <TouchableOpacity style={styles.button} onPress={navigateToSoForArama}>
                        <Text style={styles.buttonText}>Müsait Şoförler</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.button} onPress={navigateToAraçlarım}>
                        <Text style={styles.buttonText}>Araçlarım</Text>
                    </TouchableOpacity>
                </View>
                <View style={styles.buttonContainer}>
                    <TouchableOpacity style={styles.button} onPress={navigateToProfilAyarları}>
                        <Text style={styles.buttonText}>Profil Ayarları</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={[styles.button, {
                        backgroundColor: '#ff2b24',
                        paddingVertical: 10,
                    }]}
                        onPress={navigateToÇıkışYap}>
                        <Text style={styles.buttonText}>Çıkış</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#f5f5f5',
    },
    text: {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 20,
    },
    buttonContainer: {
        flexDirection: 'col',
        justifyContent: 'space-between',
        marginBottom: 20,
    },
    button: {
        backgroundColor: '#007bff',
        paddingVertical: 31,
        paddingHorizontal: 62,
        borderRadius: 8,
        marginBottom: 10, // Butonlar arasındaki boşlukları ayarlar
        flex: 1, // İki butonun da eşit genişlikte olmasını sağlar
    },
    buttonText: {
        color: '#ffffff',
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'center',
    },
});

export default CompanyDashboardScreen;
