import { apiBase } from "./constants";

const fetchDrivers = async (bodyparams) => {
    // Fetch drivers based on search parameters and page


    try {
        const response = await fetch(apiBase + '/wp-json/eliyteSurfer/v1/driver/search', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(bodyparams),
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const result = await response.json();
        // Process the response and return it (assuming the response contains a 'data' field with the list of vehicles)
        return result;
    } catch (error) {
        console.error('Error fetching vehicles:', error);
        // Handle errors here as needed, you can throw an error or return an error object.
        // For simplicity, I'm returning null in case of an error.
        return null;
    }
};

export default fetchDrivers;