import React from 'react';
import { View, TextInput, StyleSheet, Text } from 'react-native';

const BabyBlueInput = ({ label, disabled, secureTextEntry, ...rest }) => {
    return (
        <View style={styles.container}>
            <Text style={styles.label}>{label}</Text>
            <TextInput
                style={styles.input}
                editable={!disabled}
                secureTextEntry={secureTextEntry}
                {...rest}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        marginBottom: 10,
    },
    label: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 5,
        color: '#00A8E8',
    },
    input: {
        height: 40,
        borderColor: '#00A8E8',
        borderWidth: 1,
        borderRadius: 5,
        paddingHorizontal: 10,
        backgroundColor: 'white',
    },
});

export default BabyBlueInput;
