import { apiBase } from "./constants";

export const searchVehicles = async (searchParams) => {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    const requestBody = {
        ...searchParams,
    };

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(requestBody),
        redirect: 'follow',
    };

    try {
        const response = await fetch(apiBase + "/wp-json/eliyteSurfer/v1" + '/vehicles/search', requestOptions);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const result = await response.json();
        // Process the response and return it (assuming the response contains a 'data' field with the list of vehicles)
        return result;
    } catch (error) {
        console.error('Error fetching vehicles:', error);
        // Handle errors here as needed, you can throw an error or return an error object.
        // For simplicity, I'm returning null in case of an error.
        return null;
    }
};
