import React from 'react';
import { View, Text } from 'react-native';

const DriverProfileScreen = () => {
    return (
        <View>
            <Text>Driver Profile Screen</Text>
            {/* Driver Profile ekranına ait diğer bileşenler */}
        </View>
    );
};

export default DriverProfileScreen;
